<template>
    <div>
        <el-card class="box-card" style="margin-bottom: 8px;">
            <div class="flex-container">
                <div>

                    <el-button type="primary" @click="openCompensateModel()">VIP / PRO 补偿</el-button>
                </div>
                <div class="right-aligned">
                    <el-input placeholder="查询内容" class="input" v-model="UserSearch.Search"></el-input>
                    <el-select v-model="UserSearch.SearchType" placeholder="选择一个类型" class="select">
                        <el-option v-for="index in UserSearch.List" :key="index.value" :label="index.label"
                            :value="index.value"></el-option>
                    </el-select>
                    <el-button type="primary" @click="search()" style="margin-left: 15px;">查询</el-button>
                </div>
            </div>
        </el-card>

        <el-card class="box-card">
            <el-table :data="UserList" style="width: 100%" v-loading="loading">
                <el-table-column prop="userid" label="用户id" width="105">
                    <template slot-scope="scope">
                        <span>{{ scope.row.userid }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="昵称" width="125">
                    <template slot-scope="scope">
                        <span>{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="phone" label="手机号" width="120">
                    <template slot-scope="scope">
                        <span>{{ scope.row.phone }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="jiasuip" label="ip前缀">
                    <template slot-scope="scope">
                        <span>{{ scope.row.jiasuip }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="login" label="登录方式">
                    <template slot-scope="scope">
                        <span>{{ scope.row.login }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="route_time" label="到期时间">
                    <template slot-scope="scope">
                        <span>{{ scope.row.route_time }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="vip" label="等级" width="105">
                    <template slot-scope="scope">
                        <span>{{ scope.row.vip }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="GameName" label="游戏名">
                    <template slot-scope="scope">
                        <span>{{ scope.row.GameName }}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="address" label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="getUpdateInfo(scope.row.userid)">编辑</el-button>
                        <el-button type="primary" style="margin-left: 10px;"
                            @click="getIP(scope.row.userid, scope.row.vip, scope.row.route_time)">独立IP</el-button>
                        <!-- <el-button type="danger" @click="Delete(scope.row.userid)">删除</el-button> -->
                    </template>
                </el-table-column>
            </el-table>

        </el-card>


        <el-dialog title="独立IP编辑" :visible.sync="independentIP.Model" width="28%">
            <span>当前用户ID：{{ independentIP.Userid }}<span style="color: red;"> {{ independentIP.UserInfo }}</span>
            </span>
            <p>分配的IP为：<el-select v-model="independentIP.IPID" placeholder="请选择">
                    <el-option v-for="item in independentIP.IPList" :key="item.iid" :label="item.ip" :value="item.iid">
                    </el-option>
                </el-select>
            </p>
            <p>
                到期时间：
                <el-date-picker v-model="independentIP.Timestamp" type="date" value-format="timestamp"
                    placeholder="选择日期">
                </el-date-picker>

                <el-button type="primary" style="margin-left: 15px;"
                    @click="independentIP.Timestamp = independentIP.Time * 1000">设置为到期时间</el-button>

            </p>
            <span style="margin-top: 15px" >时间戳：{{ independentIP.Timestamp }}</span>


            <div slot="footer" class="dialog-footer">
                <el-button @click="outerVisible = false">取消</el-button>
                <el-button type="primary" style="margin-left: 15px;" @click="updateIP()">提交信息</el-button>
            </div>
        </el-dialog>


        <el-dialog title="VIP / PRO 补偿" :visible.sync="Compensate.Model" width="25%">
            <div style="margin-bottom: 30px;">
                <el-radio v-model="Compensate.Type" label="vip">VIP</el-radio>
                <el-radio v-model="Compensate.Type" label="pro">PRO</el-radio>
            </div>
            <p>请输入天数</p>
            <el-input placeholder="补偿天数" v-model="Compensate.Days"></el-input>

            <div class="block" style="margin-top: 15px;">
                <span class="demonstration">到期时间未超过该日期： </span>
                <el-date-picker v-model="Compensate.Timestamp" type="date" value-format="timestamp" placeholder="选择日期">
                </el-date-picker>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="outerVisible = false">取消</el-button>
                <el-button type="primary" style="margin-left: 15px;" @click="vipCompensate()">提交信息</el-button>
            </div>
        </el-dialog>


        <el-dialog title="编辑信息" :visible.sync="outerVisible">
            <!-- <el-dialog width="30%" title="内层 Dialog" :visible.sync="innerVisible" append-to-body>
            </el-dialog> -->
            <el-input placeholder="尚未获取到ID" :disabled="true" v-model="UpdateChace.userid" class="eli">
                <template slot="prepend">用户ID：</template>
            </el-input>

            <el-input placeholder="请输入内容" v-model="UpdateChace.name" class="eli">
                <template slot="prepend">用户昵称：</template>
            </el-input>
            <el-input placeholder="默认为0" v-model="UpdateChace.phone" class="eli">
                <template slot="prepend">手机号：</template>
            </el-input>
            <el-input placeholder="默认为null，vip为13位+识别码，pro为自定义" v-model="UpdateChace.jiasuip" class="eli">
                <template slot="prepend">加速IP前缀：</template>
            </el-input>
            <el-input placeholder="百度bd 微信wx QQ 手机号ph" v-model="UpdateChace.login" class="eli">
                <template slot="prepend">登录方式：</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="UpdateChace.loginid" class="eli">
                <template slot="prepend">登录API用户识别码：</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="UpdateChace.route" class="eli">
                <template slot="prepend">使用线路：</template>
            </el-input>

            <el-row :gutter="20">

                <el-col :span="16">
                    <el-input placeholder="请输入内容" v-model="addSum_" class="eli">
                        <template slot="prepend">到期时间：</template>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-input-number v-model="num" @change="addDay" :min="0" :max="365" class="eli" style="width: 100%;"
                        label="描述文字"></el-input-number>
                </el-col>
                <el-col :span="4" style="padding: 15px 5px;">
                    <div style="width: 100%;">
                        {{ timeToDate }}
                    </div>
                </el-col>

            </el-row>

            <el-input placeholder="请输入内容" v-model="UpdateChace.vip" class="eli">
                <template slot="prepend">用户等级：</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="UpdateChace.token" class="eli">
                <template slot="prepend">用户认证秘钥：</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="UpdateChace.GameName" class="eli">
                <template slot="prepend">游戏昵称：</template>
            </el-input>
            <el-input placeholder="请输入内容" v-model="UpdateChace.server" class="eli">
                <template slot="prepend">目标加速服务器：</template>
            </el-input>

            <div slot="footer" class="dialog-footer">
                <el-button @click="outerVisible = false">取消</el-button>
                <el-button type="primary" @click="queryUpdateInfo()">提交信息</el-button>
            </div>
        </el-dialog>


    </div>
</template>

<script>
import { getIndependentIPList, getUserList, ifIndependentIP, searchUserList, updateCompensate, updateIndependentIP, updateUserList } from '@/utils/api';
export default {
    data() {
        return {
            num: 0,
            addDay_: 0,
            addSum_: 0,
            timeToDate: "？？",
            Compensate: {
                Model: false,
                Days: 0,
                Type: "vip",
                Timestamp: ''
            },
            independentIP: {
                Model: false,
                UserInfo: "",
                IPID: "",
                IPList: "",
                Userid: "",
                UserLevel: "",
                Timestamp: "",
                Time: ""

            },
            outerVisible: false,
            innerVisible: false,
            loading: true,
            UserSearch: {
                Search: "",
                SearchType: "",
                List: [
                    { "label": "用户UID", "value": "userid" },
                    { "label": "用户昵称", "value": "name" },
                    { "label": "手机号", "value": "phone" },
                    { "label": "加速IP前缀", "value": "jiasuip" },
                    { "label": "到期时间", "value": "route_time" },
                    { "label": "用户等级", "value": "vip" },
                    { "label": "游戏昵称", "value": "GameName" }
                ]
            },
            UpdateChace: {},
            UserList: [],
        }
    },
    mounted() {
        // 初始化类型列表
        this.getList(1);
        // 获取时间戳
        this.Compensate.Timestamp = new Date().getTime();
    },
    methods: {
        updateIP() {
            updateIndependentIP(this.$cookies.get('atoken'), this.independentIP.IPID, this.independentIP.Userid, this.independentIP.Timestamp).then((res) => {
                if (res.data.code == 'error') {
                    this.infromError('自动登录失败，请检查账号密码')
                } else {
                    this.infromSuccess('更新成功')
                }
            })
        },

        getIP(userid, vip, time) {
            this.independentIP.Userid = userid;
            this.independentIP.UserLevel = vip;
            this.independentIP.Time = time;

            if (vip != 3) {
                this.independentIP.UserInfo = "（该用户不是PRO套餐）"

            } else {

                ifIndependentIP(this.$cookies.get('atoken'), userid).then((res) => {
                    if (res.data.code == 'error') {
                        this.independentIP.UserInfo = res.data.msg;
                    } else {
                        this.independentIP.UserInfo = "";
                    }
                })
            }

            getIndependentIPList(this.$cookies.get('atoken')).then((res) => {
                if (res.data.code == 'error') {
                    this.infromError('自动登录失败，请检查账号密码')
                } else {
                    this.independentIP.IPList = res.data;
                }
            })




            this.independentIP.Model = true;


        },
        getList(number) {
            this.loading = true;
            getUserList(this.$cookies.get('atoken'), number).then((res) => {
                if (res.data.code == 'error') {
                    this.infromError('自动登录失败，请检查账号密码')
                } else {
                    this.UserList = res.data;
                    this.loading = false;
                }
            })

        },
        search() {
            this.loading = true;
            searchUserList(this.$cookies.get('atoken'), this.UserSearch.SearchType, this.UserSearch.Search).then((res) => {
                if (res.data.code == 'error') {
                    this.infromError('自动登录失败，请检查账号密码')
                } else {
                    this.UserList = res.data;
                    this.loading = false;
                }
            })
        },
        openCompensateModel() {
            this.Compensate.Model = true;
        },
        vipCompensate() {
            updateCompensate(this.$cookies.get('atoken'), this.Compensate.Days, this.Compensate.Type, this.Compensate.Timestamp).then((res) => {
                if (res.data.code == 'error') {
                    this.infromError('自动登录失败，请检查账号密码')
                } else {
                    this.infromSuccess(res.data.data);
                    this.Compensate.Model = false;
                }
            })
        },
        getUpdateInfo(id) {
            this.UpdateChace = {};
            searchUserList(this.$cookies.get('atoken'), "userid", id).then((res) => {
                if (res.data.code == 'error') {
                    this.infromError('自动登录失败，请检查账号密码')
                } else {
                    this.UpdateChace = res.data[0];
                    this.addSum_ = this.UpdateChace.route_time;
                    this.timeToDate = new Date(this.addSum_ * 1000).toLocaleString();
                    this.outerVisible = true;
                }
            })
        },
        queryUpdateInfo() {
            updateUserList(
                this.$cookies.get('atoken'),
                this.UpdateChace.userid,
                this.UpdateChace.name,
                this.UpdateChace.phone,
                this.UpdateChace.jiasuip,
                this.UpdateChace.login,
                this.UpdateChace.loginid,
                this.UpdateChace.route,
                // this.UpdateChace.route_time,
                this.addSum_,
                this.UpdateChace.vip,
                this.UpdateChace.token,
                this.UpdateChace.GameName,
                this.UpdateChace.server,
            ).then((res) => {
                if (res.data.code == 'error') {
                    this.infromError('自动登录失败，请检查账号密码')
                } else {
                    this.infromSuccess('成功');
                    this.outerVisible = false;
                }
            })
        },
        infromError(text) {
            this.$notify.error({
                title: 'Error',
                message: text,
                duration: 3000
            });
        },
        infromSuccess(text) {
            this.$notify.success({
                title: 'Success',
                message: text,
                duration: 3000
            });
        },
        handlePageChange(newPage) {
            this.currentPage = newPage;
            console.log(newPage);
        },
        addDay(value) {
            this.addDay_ = value * 86400;
            this.addSum_ = this.UpdateChace.route_time + this.addDay_;
            console.log(this.addSum_)
            this.timeToDate = new Date(this.addSum_ * 1000).toLocaleString();

        }


    }
}
</script>

<style>
.right-aligned-buttons {
    display: flex;
    justify-content: flex-end;
}

.right-aligned-buttons>* {
    margin-left: 10px;
}

.r-content {
    flex: 1;
    text-align: right;
}

.type-container {
    padding: 0;
    height: 62.44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.input {
    width: 300px;
    margin-left: 15px;
}

.select {
    margin-left: 15px;
}

.el-card__body {
    padding: 15px;
}

.flex-container {
    display: flex;
    justify-content: flex-end;
    /* 将内容右对齐 */
}

.right-aligned {
    margin-left: auto;
    /* 将右对齐的内容推向右侧 */
}

.eli {
    margin: 7px 0px 7px 0px;
}
</style>