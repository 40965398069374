<template>
    <div class="loginbody">
        <div class="logindata"
            style="background-color: rgba(255,255,255,85%); padding: 20px 50px 0px 50px; border-radius: 10px;box-shadow: 1px 1px 20px #5891c3">
            <div class="logintext">
                <h2>YJS Console</h2>
            </div>
            <div class="formdata">
                <el-form ref="form" :model="form" :rules="rules">
                    <el-form-item prop="password">
                        <el-input v-model="form.password" clearable placeholder="请输入密码" show-password></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div class="butt">
                <el-button type="primary" @click.native.prevent="login()" style="width:100%">登录</el-button>
            </div>
        </div>
    </div>
</template>
  
<script>
import { LoginCheck } from './../utils/api';
export default {
    name: "login",
    data() {
        return {
            form: {
                password: "",
            },
            checked: false,
            rules: {
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                    { max: 50, message: "不能大于50个字符", trigger: "blur" },
                ],
            },
        };
    },
    mounted() {
        if (localStorage.getItem("news")) {
            this.form = JSON.parse(localStorage.getItem("news"))
            this.checked = true
        }
    },
    methods: {
        login() {
            if (this.form.password != '') {
                LoginCheck(this.form.password).then((res) => {
                    if (res.data.code == 'success') {
                        this.$cookies.set('atoken', this.form.password);
                        this.$router.push('UserMana');
                        this.infromSuccess('登录成功');
                    } else if (res.data.code == 'error') {
                        this.infromError('密码错误');
                    } else {
                        this.infromError('请求错误');
                    }
                })
            } else {
                this.infromError('请输入密码');
            }
        },
        infromError(text) {
            this.$notify.error({
                title: 'Error',
                message: text,
                duration: 3000
            });
        },
        infromSuccess(text) {
            this.$notify.success({
                title: 'Success',
                message: text,
                duration: 3000
            });
        }


    },
};
</script>
  
<style scoped>
.loginbody {
    width: 100%;
    height: 100%;
    min-width: 1000px;
    background-image: url("../assets/bg.webp");
    background-size: 100% 100%;
    background-position: center center;
    overflow: auto;
    background-repeat: no-repeat;
    position: fixed;
    line-height: 100%;
    padding-top: 150px;
}

.logintext {
    margin-bottom: 20px;
    line-height: 50px;
    text-align: center;
    font-size: 30px;
    font-weight: bolder;
    color: rgb(255, 255, 255);
    text-shadow: 0px 0px 2px #5088bb;
}

.logindata {
    width: 400px;
    height: 300px;
    transform: translate(-50%);
    margin-left: 50%;
}

.tool {
    display: flex;
    justify-content: space-between;
    color: #ffffff;
}

.butt {
    margin-top: 10px;
    text-align: center;
}

.shou {
    cursor: pointer;
    color: #606266;
}
</style>