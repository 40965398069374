<template>
    <div>
啥也没有

    </div>
</template>

<script>
import { DebugTool_ChangeMember } from '@/utils/api';
export default {
    name: "DebugTool",
    data() {
        return {
        }
    },
    methods:{
        ChangeMember(type){
            DebugTool_ChangeMember(this.$cookies.get('atoken'),type).then((res)=>{
                if (res.data.code == 'error') {
                    this.infromError(res.data.data)
                } else {
                    this.infromSuccess(res.data.data)
                }
            })
        },
        infromError(text) {
            this.$notify.error({
                title: 'Error',
                message: text,
                duration: 3000
            });
        },
        infromSuccess(text) {
            this.$notify.success({
                title: 'Success',
                message: text,
                duration: 3000
            });
        },
    }
}
</script>

<style>
.debugButton {
    margin: 5px 0px 5px 0;
    width: 100%;
}

.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 0px;
}
</style>