<template>
    <div style="padding: 0; margin: 0;">
        <el-menu default-active="1" class="el-menu-vertical-demo"
            :collapse="isCollapse" background-color="#34343b" text-color="#999999" active-text-color="#fff">
            <h3 style="text-align: center; color: rgb(136, 150, 163);">YJS Console</h3>
            <el-menu-item @click="pushRouter('UserMana')" style=" border-top: 1px solid #3c444b;" index="1">
                <i class="el-icon-user-solid"></i>
                <span slot="title">用户管理</span>
            </el-menu-item>
            <el-menu-item @click="pushRouter('DebugTool')" index="2">
                <i class="el-icon-user-solid"></i>
                <span slot="title">调试工具</span>
            </el-menu-item>
            <!-- <el-menu-item @click="pushRouter('TypeEdit')" index="2">
                <i class="el-icon-setting"></i>
                <span slot="title">类型编辑</span>
            </el-menu-item> -->
        </el-menu>


    </div>
</template>

<script>
export default {
    data() {
        return {
            isCollapse: false
        };
    },
    methods: {
        pushRouter(src) {
            this.$router.push(src).catch(() => { });
        }
    }
}
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    height: 100vh;
    border:0!important;
}

.aside-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0rpx;
}
</style>