import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../components/Main'
import Login from '../components/Login'
import UserMana from '../components/tools/UserMana'
import DebugTool from '../components/tools/DebugTool'
import VueCookie from 'vue-cookies'

Vue.use(VueCookie);
Vue.use(VueRouter);

const routes = [
    // 主路由
    {
        path: '/',
        component: Main,
        redirect: '/Login',
        children: [
            // 子路由
            {
                path: 'UserMana',
                component: UserMana,
                name: 'UserMana'
            },
            {
                path: "DebugTool",
                component: DebugTool,
                name: "DebugTool"
            }
        ]
    },
    {
        path: '/Login',
        component: Login,
        name: 'Login'
    }
]



const router = new VueRouter({
    routes
})
router.beforeEach(async (to, from, next) => {
    const token = VueCookie.isKey('atoken');
    // token不存在并且 当前页面不在Login 则前往Login
    if (token == false && to.name !== 'Login') {
        next({ name: 'Login' })
    }
    else if (token == true && to.name === 'Login') {
        next({ name: 'UserMana' })
    }
    else {
        next()
    }
})

export default router