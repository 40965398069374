import http from './request'

/* 首次登录相关 */
export const LoginCheck = (token) => {
    const formData = new FormData();
    formData.append('token', token);
    return http.post(`/Login/check`, formData);
}

/* UserMana - 用户管理模块 */

// 获取主页数据
export const getUserList = (token) => {
    const formData = new FormData();
    formData.append('token', token);
    return http.post(`/UserMana/query`, formData);
}

// 搜索数据
export const searchUserList = (token, type, keywords) => {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('type', type);
    formData.append('keywords', keywords);
    return http.post(`/UserMana/search`, formData);
}

// 编辑数据
export const updateUserList = (token, userid, name, phone, jiasuip, login, loginid, route, route_time, vip, utoken, GameName, server) => {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('userid', userid);
    formData.append('name', name);
    formData.append('phone', phone);
    if (vip == 2)
        formData.append('jiasuip', jiasuip);
    else
        formData.append('jiasuip', '');
    formData.append('login', login);
    formData.append('loginid', loginid);
    formData.append('route', route);
    formData.append('route_time', route_time);
    formData.append('vip', vip);
    formData.append('utoken', utoken);
    formData.append('GameName', GameName);
    formData.append('server', server);
    return http.post(`/UserMana/update`, formData);
}

// 补偿提交
export const updateCompensate = (token, days, type, timestamp) => {
    // 处理数字后四位
    timestamp = timestamp.toString();
    timestamp = timestamp.slice(0, -4);
    timestamp = parseInt(timestamp);

    const formData = new FormData();
    formData.append('token', token);
    formData.append('days', days);
    formData.append('type', type);
    formData.append('timestamp', timestamp);

    return http.post(`/UserMana/compensate`, formData);
}



// 独立IP
export const updateIndependentIP = (token, ipid, userid, timestamp) => {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('ipid', ipid);
    formData.append('userid', userid);
    formData.append('timestamp', timestamp);

    return http.post(`/UserMana/independentip`, formData);
}

export const getIndependentIPList = (token) => {
    const formData = new FormData();
    formData.append('token', token);

    return http.post(`/UserMana/getip`, formData);
}

export const ifIndependentIP = (token, userid) => {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('userid', userid);

    return http.post(`/UserMana/ifindependentip`, formData);
}



/* DebugTool */

// 更改账号套餐
export const DebugTool_ChangeMember = (token, type) => {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('type', type);
    return http.post(`/DebugTool/Msirp`, formData);
}