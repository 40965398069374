import axios from 'axios'

const http = axios.create({
    baseURL: 'https://admin.yjiasu.com/index.php',
    timeout: 10000
})

// 请求拦截器
axios.interceptors.request.use(function (config) {
    console.log(config)
})

// 响应拦截器
axios.interceptors.response.use(function (res) {
    console.log(res)
})

export default http