<template>
    <div class="header-container">
        <div class="r-content">
            <el-button @click="outLogin" style="background-color: #34343b; border: 0;">退出登录</el-button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        outLogin() {
            this.$cookies.remove('atoken');
            this.$router.push('Login');
            this.infromSuccess('已退出登录');
        },
        infromError(text) {
            this.$notify.error({
                title: 'Error',
                message: text,
                duration: 3000
            });
        },
        infromSuccess(text) {
            this.$notify.success({
                title: 'Success',
                message: text,
                duration: 3000
            });
        }
    }
}
</script>

<style>
.r-content {
    flex: 1;
    text-align: right;
}

.header-container {
    padding: 0;
    background-color: #34343b;
    height: 62.44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 1px solid #3c444b;
    border-bottom: 1px solid #3c444b;
}
</style>