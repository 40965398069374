<template>
    <div>
        <el-container>
            <el-aside width="230px" >
                <Aside />
            </el-aside>
            <el-container>
                <el-header style="padding: 0;">
                    <Header/>
                </el-header>
                <el-main><router-view></router-view></el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import Aside from './Aside.vue'
import Header from './Header.vue'
export default {
    data() {
        return {}
    },
    components: {
        Header,Aside
    }
}
</script>

<style></style>